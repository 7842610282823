<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                        >
                        {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    </v-breadcrumbs>
            </v-col>
            
            <v-col cols="12">
                <v-card>
                     <v-progress-linear
                        :active="$store.state.masterMaintenanceCategory.isProgress"
                        :indeterminate="$store.state.masterMaintenanceCategory.isProgress"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="filter_desc"
                                label="Description"
                                @keyup.enter="search"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-5"></v-divider>
                        <v-row class="mt-5">
                            <v-col cols="6" class="text-left">
                                <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="indigo"
                                @click="createNew()"
                                >
                                <v-icon dark> mdi-plus </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn
                                class="mr-2"
                                color="error"
                                elevation="2"
                                large
                                rounded
                                @click="search()"
                                >
                                <v-icon dark> mdi-magnify </v-icon>
                                </v-btn>
                            </v-col>
                            </v-row>
                            <v-snackbar
                                v-model="snackbar.visible"
                                :color="snackbar.color"
                                :multi-line="snackbar.mode === 'multi-line'"
                                :timeout="snackbar.timeout"
                                :top="snackbar.position === 'top'"
                            >
                            <v-layout align-center pr-4>
                                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                                </v-layout>
                            </v-layout>
                            <v-btn
                                v-if="snackbar.timeout === 0"
                                icon
                                @click="snackbar.visible = false"
                            >
                                <v-icon>clear</v-icon>
                            </v-btn>
                            </v-snackbar>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="$store.state.jsonData.master.maintenanceCategoryData.data.data"
                                    :options.sync="option"
                                    
                                    :server-items-length="$store.state.jsonData.master.maintenanceCategoryData.data.total"
                                    loading-text="Please wait, retrieving data"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                <!-- :page.sync="$store.state.jsonData.master.maintenanceCategoryData.data.currentPage" -->
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                            small
                                            class="mr-2"
                                            @click="editItem(item)"
                                            v-on="on"
                                            >
                                            mdi-pencil
                                            </v-icon>
                                        </template>
                                        <span>Edit</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <dialog-input
                    :visible="$store.state.masterMaintenanceCategory.dialogVisible"
                    :btSave="dialog.btSave"
                    @is-saved="dialogInputEmit"
                ></dialog-input>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import DialogInput from "../../../../components/Layouts/Partials/kka/maintenance/master/dialog_maintenance_category.vue";

export default {
    components: {
        DialogInput,
    },
    data(){
        return{
            breadcumbs: [
                {
                text: "KKA",
                disabled: false,
                href: "/admin/kka",
                },
                {
                text: "KKA Maintenance",
                disabled: false,
                href: "/admin/kka/maintenance",
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            headers: [
                {
                text: "Maintenance Category ID",
                align: "start",
                value: "category_id",
                },
                { text: "Description", value: "description" },
                { text: "Active", value: "is_active"},
                { text: "Actions", value: "actions", sortable: false },
            ],
            filter_desc:"",
            option: {},
            dataTabel:[],
            dataFilter:{
                category_id:"",
                description:"",
                is_active:"",
                page:1,
                item_per_page:10,
            },
            dialog:{
                btSave:"",
                switchActive:true,
                description:"",
            },
            totalItems:10,
        }
    },
    mounted() {
        this.clearVar();
        this.search();
    },
    methods: {
        clearVar(){
            this.$store.state.masterMaintenanceCategory.dialogVisible = false;
            this.$store.state.masterMaintenanceCategory.data.description = "";
            this.$store.state.masterMaintenanceCategory.data.isActive = true;
        },
        createNew(){
            console.log("Create New");
            this.dialog.btSave = "Save";
            // this.dialog.switchActive = true;
            // this.dialog.dialogVisible = true;
            this.$store.state.masterMaintenanceCategory.data.isActive = true;
            this.$store.state.masterMaintenanceCategory.dialogVisible = true;
        },
        editItem(item){
            console.log("edititem");
            console.log(item);
            this.$store.state.masterMaintenanceCategory.data.category_id = item.category_id;
            this.$store.state.masterMaintenanceCategory.data.description = item.description;
            if (item.is_active === "1") {
                this.$store.state.masterMaintenanceCategory.data.isActive = true;
            } else {
                this.$store.state.masterMaintenanceCategory.data.isActive = false;
            }
            this.dialog.btSave = "Update";
            // console.log('store = ' + this.$store.state.masterMachineCategory);
            // console.log('store = ' + this.$store.state.masterMachineCategory.description);
            // console.log('store = ' + this.$store.state.masterMachineCategory.switchActive);
            this.$store.state.masterMaintenanceCategory.dialogVisible = true;
        },
        dialogInputEmit(payload){
            this.snackbar = payload.snackbar;
        },
        search(page = 1, itemsPerPage = 10){
            let data = this.dataFilter;
            console.log("search");
            console.log(page);
            if(this.filter_desc !== ""){
                data.description = this.filter_desc;
            }
            data.page = page;
            data.item_per_page = itemsPerPage;
            console.log(data);
            // console.log(data.item_per_page);
            this.$store.state.masterMaintenanceCategory.isProgress = true;
            this.$store
              .dispatch("getMasterMaintenanceCategory", data)
              .then(() => {
                this.$store.state.masterMaintenanceCategory.isProgress = false;
                console.log("SEARCH");
                // var data = this.$store.state.jsonData.master.maintenanceCategoryData;
                console.log(this.$store.state.jsonData.master.maintenanceCategoryData);
                console.log(this.$store.state.jsonData.master.maintenanceCategoryData.data.total);
                // this.dataTabel = data.data.data;
                this.totalItems = data.data.total;
                // console.log(totalItems);
              })
              .catch((err) => {
                //   console.log("errror ");
                //   console.log(err);
                //   console.log(err.response);
                //   console.log(err.response.data);
                //   console.log(err.response.status);
                this.$store.state.masterMaintenanceCategory.isProgress = false;
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: err.response.status + " " + err.response.statusText,
                  visible: true
                };
              });
        },
    },
    watch: {
        option: {
            handler() {
                this.$store.state.masterMaintenanceCategory.isProgress = true;
                const { page, itemsPerPage } = this.option;
                // console.log("page = " + this.page);
                // console.log("items per page = " + this.itemsPerPage);
                console.log("opsi");
                console.log(this.option);
                this.$store.state.masterMaintenanceCategory.page = page;
                this.$store.state.masterMaintenanceCategory.itemPerPage = itemsPerPage
                // if (
                //   this.mill != "" &&
                //   // this.warehouse != "" &&
                //   // this.warehouse_dest != "" &&
                //   this.value != null
                // ) {
                this.search(page, itemsPerPage);
                // }
            },
            deep: true,
        },
    },
}
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>