<template>
    <v-dialog v-model="visible" max-width="500px" persistent>
        <v-card>
        <v-toolbar color="primary" dark> Maintenance Category </v-toolbar>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-purple accent-4"
        ></v-progress-linear>

        <v-card-text>
            <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="$store.state.masterMaintenanceCategory.data.description"
                        counter="255"
                        hint="Max 255 char"
                        :rules="[rules.required, rules.min]"
                        label="Machine Category"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-switch
                        v-model="$store.state.masterMaintenanceCategory.data.isActive"
                        :label="`Active: ${$store.state.masterMaintenanceCategory.data.isActive}`"
                    ></v-switch>
                </v-col>
            </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> {{btSave}} </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    props: {
        visible:Boolean,
        btSave:"",
    },
    data() {
        return{
            loading:false,
            isSuccess:false,
            rules: {
                required: value => !!value || 'Required.',
                max: v => v.length <= 255 || 'Max 255 characters',
            },
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            dataFilter:{
                category_id:"",
                description:"",
                is_active:"",
                page:1,
                item_per_page:10,
            },
        }
    },
    mounted(){
    },
    methods:{
        clearVar(){
            this.$store.state.masterMaintenanceCategory.data.category_id= "";
            this.$store.state.masterMaintenanceCategory.data.description = "";
            this.$store.state.masterMaintenanceCategory.data.isActive=true;
        },
        close(){
            this.clearVar();
            this.$store.state.masterMaintenanceCategory.dialogVisible = false;
        },

        async save(){
            console.log("save");
            var id = this.$store.state.masterMaintenanceCategory.data.category_id;
            var description = this.$store.state.masterMaintenanceCategory.data.description;
            var activeFlag = this.$store.state.masterMaintenanceCategory.data.isActive;
            var data = this.dataFilter;
            data.page = this.$store.state.masterMaintenanceCategory.page,
            data.item_per_page = this.$store.state.masterMaintenanceCategory.itemPerPage,
            console.log("description = " + description);
            console.log("activeFlag = " + activeFlag);
            if (description !== '') {
                this.loading=true;
                if(this.btSave == "Save"){
                    console.log("save");
                    await axios.post(`${process.env.VUE_APP_URL}/api/master/kka/maintenance_category`, {
                        // 'descr': this.description,
                        // 'active_flag': activeFlag,
                        'description': description,
                        'is_active': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.loading = false;
                        this.isSuccess = true;
                        this.$store.state.masterMaintenanceCategory.isProgress = true;
                        
                        this.$store.dispatch("getMasterMaintenanceCategory", { data }).then(() => {
                                this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Insert Maintenance Category",
                                visible: true
                            };
                            // this.visible = false;
                            this.$emit('is-saved', { snackbar: this.snackbar});
                            this.isSuccess = false;
                            this.$store.state.masterMaintenanceCategory.isProgress = false;
                            this.close();
                        })
                        .catch((err) => {
                            //   console.log("errror ");
                            //   console.log(err);
                            //   console.log(err.response);
                            //   console.log(err.response.data);
                            //   console.log(err.response.status);
                            this.$store.state.masterMaintenanceCategory.isProgress = false;
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.status + " " + err.response.statusText,
                                visible: true
                            };
                        });

                        
                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { snackbar: this.snackbar })
                    })
                } else {
                    console.log("update");
                    await axios.put(`${process.env.VUE_APP_URL}/api/master/kka/maintenance_category/update`, {
                        'category_id': id,
                        'description': description,
                        'is_active': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        console.log("update berhasil");
                        this.loading = false;
                        this.isSuccess = true;
                        this.$store.state.masterMaintenanceCategory.isProgress = true;
                        // var data = this.dataFilter;
                        this.$store.dispatch("getMasterMaintenanceCategory", { data }).then(() => {
                                this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Update Maintenance Category",
                                visible: true
                            };
                            // this.visible = false;
                            this.$emit('is-saved', { snackbar: this.snackbar});
                            this.isSuccess = false;
                            this.$store.state.masterMaintenanceCategory.isProgress = false;
                            this.close();
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.isSuccess = false;
                            //   console.log("errror ");
                            //   console.log(err);
                            //   console.log(err.response);
                            //   console.log(err.response.data);
                            //   console.log(err.response.status);
                            this.$store.state.masterMaintenanceCategory.isProgress = false;
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.status + " " + err.response.statusText,
                                visible: true
                            };
                            this.$emit('is-saved', {snackbar: this.snackbar});
                        });

                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { snackbar: this.snackbar })
                    })
                }
                
            }
        }
    },
}
</script>
